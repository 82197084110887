// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-12-minuten-js": () => import("./../../../src/pages/12-minuten.js" /* webpackChunkName: "component---src-pages-12-minuten-js" */),
  "component---src-pages-impressum-js": () => import("./../../../src/pages/impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-pyramid-2021-js": () => import("./../../../src/pages/pyramid-2021.js" /* webpackChunkName: "component---src-pages-pyramid-2021-js" */),
  "component---src-pages-update-needed-js": () => import("./../../../src/pages/update-needed.js" /* webpackChunkName: "component---src-pages-update-needed-js" */)
}

